
/* eslint-disable */
import { defineComponent, Ref, ref } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    label: String,
    value: String,
  },
  setup(props, { emit }) {
    //#region Data
    const inputValue = ref('');
    const timeout: Ref<any> = ref(null);
    //#endregion
    
    //#region Methods
    const inputValueChanged = () => {
      if (timeout.value) {
        clearTimeout(timeout.value);
      }

      timeout.value = setTimeout(() => {
        emit('update:value', inputValue.value);
      }, 300);
    }
    //#endregion
    
    return {
      props,

      //#region Data
      inputValue,
      //#endregion
      
      //#region Methods
      inputValueChanged,
      //#endregion
    }
  },
})
